import { Controller } from "@hotwired/stimulus"
import jscolor from "@eastdesire/jscolor"

// Connects to data-controller="colourway"
export default class extends Controller {
  static targets = ["colourway", "background", "accent", "picker"];

  connect() {
    document.addEventListener("DOMContentLoaded", () => {
      jscolor.install();
    });

    this.fetchColourway();
  }

  fetchColourway() {
    const colourwayId = this.pickerTarget.value;

    if (colourwayId === "") {
      this.backgroundTarget.jscolor.fromString(this.backgroundTarget.dataset.colourwayDefault.replace(/#/g, ''));
      this.accentTarget.jscolor.fromString(this.accentTarget.dataset.colourwayDefault.replace(/#/g, ''));
    } else {
      fetch(`/colourways/${colourwayId}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          this.backgroundTarget.jscolor.fromString(data.background_colour.replace(/#/g, ''));
          this.accentTarget.jscolor.fromString(data.accent_colour.replace(/#/g, ''));
        })
        .catch(error => console.error('There was a problem with the fetch operation:', error));
    }
  }
}
