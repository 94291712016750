import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-form"
export default class extends Controller {
  static targets = ["imageType", "headerImage", "bothSelect", "storySelect", "postSelect"]

  connect() {
    if (this.imageTypeTarget.value === "both") {
      this.clickBoth()
    } else if (this.imageTypeTarget.value === "story") {
      this.clickStory()
    } else {
      this.clickPost()
    }
  }

  clickBoth() {
    this.highlightPanel(this.bothSelectTarget)
    this.resetPanel(this.storySelectTarget)
    this.resetPanel(this.postSelectTarget)

    this.headerImageTarget.classList.remove("hidden")
    this.imageTypeTarget.value = "both"
  }

  clickStory() {
    this.highlightPanel(this.storySelectTarget)
    this.resetPanel(this.bothSelectTarget)
    this.resetPanel(this.postSelectTarget)

    this.headerImageTarget.classList.remove("hidden")
    this.imageTypeTarget.value = "story"
  }

  clickPost() {
    this.highlightPanel(this.postSelectTarget)
    this.resetPanel(this.bothSelectTarget)
    this.resetPanel(this.storySelectTarget)

    this.headerImageTarget.classList.add("hidden")
    this.imageTypeTarget.value = "post"
  }

  /* Highlights the selected panel */
  highlightPanel(target) {
    target.classList.add("bg-sky-700");
    target.classList.add("text-white");

    target.parentElement.classList.add("ring-2");
    target.parentElement.classList.add("ring-sky-700");
    target.parentElement.classList.remove("ring-1");
    target.parentElement.classList.remove("ring-gray-400");
  }

  /* Resets the panel to its original state */
  resetPanel(target) {
    target.classList.remove("bg-sky-700")
    target.classList.remove("text-white")

    target.parentElement.classList.remove("ring-2")
    target.parentElement.classList.remove("ring-sky-700")
    target.parentElement.classList.add("ring-1")
    target.parentElement.classList.add("ring-gray-400")
  }
}
