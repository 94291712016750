import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="folder"
export default class extends Controller {
  static targets = ["dialog", "deleteImages", "wrapper"];

  connect() {
    this.dialogTarget.classList.add("hidden");
  }

  show() {
    this.deleteImagesTarget.checked = false;
    this.dialogTarget.classList.remove("hidden");
  }

  cancel() {
    this.dialogTarget.classList.add("hidden");
  }

  delete() {
    const url = new URL(this.wrapperTarget.getAttribute("data-url"));
    const deleteImages = this.deleteImagesTarget.checked;
    if (deleteImages) {
      url.searchParams.append("delete_images", "true");
    }

    fetch(url, {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")
          .content,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          this.wrapperTarget.remove();
          if (!deleteImages) {
            location.reload();
          }
        } else {
          console.error("Failed to delete the folder.");
        }
      })
      .catch((error) => console.error("Error:", error));
  }
}
