import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="timezone"
export default class extends Controller {
  static targets = [ "select", "button" ]

  connect() {
    if (!this.selectTarget.value) {
      this.selectTarget.value = this.#systemTimeZone;
    }
  }

  get #systemTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  applySystemTimeZone() {
    this.selectTarget.value = this.#systemTimeZone;
  }
}
