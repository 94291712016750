import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="gallery-picker"
export default class extends Controller {
  static targets = ["galleryDialog", "image", "input"];

  connect() {
    this.galleryDialogTarget.classList.add("hidden");
  }

  show() {
    this.galleryDialogTarget.classList.remove("hidden");
  }

  hide() {
    this.galleryDialogTarget.classList.add("hidden");
  }

  select(event) {
    this.imageTarget.src = event.target.src;
    this.inputTarget.value = event.target.dataset.imageId;
    this.hide();
  }
}
