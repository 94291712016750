import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="account"
export default class extends Controller {
  connect() {}

  // Workaround for the confirmation dialog
  delete(event) {
    let confirmed = confirm("Are you sure you want to delete your Caddiepress account?")

    if (!confirmed) {
      event.preventDefault()
    }
  }
}
