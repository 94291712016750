import { Controller } from "@hotwired/stimulus"
import { toggle } from "el-transition"

// Connects to data-controller="people-actions"
export default class extends Controller {
  static targets = ["actionMenu"];

  // Toggles the visibility of the actions menu
  // Connects to data-action="click->people-actions#toggleActionsMenu"
  toggleActionsMenu() {
    const isVisible = !this.actionMenuTarget.classList.contains('hidden');
    const divElements = document.querySelectorAll('div');

    divElements.forEach(function(div) {
      if (div.hasAttribute('data-people-actions-target')) {
        div.classList.add('hidden');
      }
    });

    if (!isVisible) {
      toggle(this.actionMenuTarget)
    }
  }

  deleteInvitation(event) {
    let confirmed = confirm("Are you sure you want to delete this invitation?")

    if (!confirmed) {
      event.preventDefault()
    }
  }

  deleteUserAccount(event) {
    let confirmed = confirm("Are you sure you want to remove this person? Removing this person will also remove all their data.")

    if (!confirmed) {
      event.preventDefault()
    }
  }
}
