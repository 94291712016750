import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="people"
export default class extends Controller {
  static targets = ["item"];

  filter(event) {
    const query = event.target.value.toLowerCase();

    this.itemTargets.forEach((item) => {
      const text = item.dataset.searchText.toLowerCase();
      if (text.includes(query)) {
        item.classList.remove("hidden");
      } else {
        item.classList.add("hidden");
      }
    });
  }
}
