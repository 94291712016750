import { Controller } from "@hotwired/stimulus"
import jscolor from '@eastdesire/jscolor'

// Connects to data-controller="color-picker"
export default class extends Controller {
  static targets = [ "inputField" ]

  connect() {
    let palette = [];

    if (this.inputFieldTarget.getAttribute("data-palette") === "background") {
      palette = [
        '#374151','#b91c1c','#c2410c','#b45309','#a16207','#4d7c0f','#15803d',
        '#065f46','#0f766e','#0e7490', '#0369a1','#1d4ed8','#4338ca','#6d28d9',
        '#7e22ce','#a21caf','#be185d','#be123c'
      ]
    }
    else {
      palette = [
        '#9ca3af','#f87171','#fb923c','#fbbf24','#facc15','#a3e635','#4ade80',
        '#34d399','#2dd4bf','#22d3ee','#38bdf8','#60a5fa','#818cf8','#a78bfa',
        '#c084fc','#e879f9','#f472b6','#fb7185'
      ]
    }

    const defaults = {
      format: 'hex',
      position: 'bottom',
      height: 80,
      palette: palette,
      paletteCols: 7,
      hideOnPaletteClick: true
    }

    this.inputFieldTarget.setAttribute("data-jscolor", JSON.stringify(defaults));
    jscolor.install();
  }
}
