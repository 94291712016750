import { Controller } from "@hotwired/stimulus"
import { toggle } from "el-transition"

export default class extends Controller {
  static targets = [ "openMenu", "closeMenu", "mobileMenu", "accountMenu" ]

  connect() { }

  toggleAccountMenu() {
    toggle(this.accountMenuTarget)
  }

  toggleMenu() {
    this.openMenuTarget.classList.toggle("block")
    this.openMenuTarget.classList.toggle("hidden")
    this.closeMenuTarget.classList.toggle("block")
    this.closeMenuTarget.classList.toggle("hidden")
    this.mobileMenuTarget.classList.toggle("hidden")
  }
}
