import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="gallery-image"
export default class extends Controller {
  static targets = [
    "deleteModal",
    "moveModal",
    "image",
    "input",
    "folderInput",
    "wrapper",
  ];

  connect() {
    this.deleteModalTarget.classList.add("hidden");
    this.moveModalTarget.classList.add("hidden");
  }

  showDeleteModal() {
    this.deleteModalTarget.classList.remove("hidden");
  }

  cancelDelete() {
    this.deleteModalTarget.classList.add("hidden");
  }

  showMoveModal() {
    this.moveModalTarget.classList.remove("hidden");
  }

  cancelMove() {
    this.moveModalTarget.classList.add("hidden");
  }

  delete() {
    const url = this.wrapperTarget.getAttribute("data-delete-url");

    fetch(url, {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")
          .content,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          this.wrapperTarget.remove();
        } else {
          console.error("Failed to delete the image.");
        }
      })
      .catch((error) => console.error("Error:", error));
  }

  move() {
    const url = this.wrapperTarget.getAttribute("data-move-url");
    const folderId = this.folderInputTarget.value;

    fetch(url, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")
          .content,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ gallery: { folder_id: folderId } }),
    })
      .then((response) => {
        if (response.ok) {
          location.reload();
        } else {
          console.error("Failed to move the image.");
        }
      })
      .catch((error) => console.error("Error:", error));
  }
}
