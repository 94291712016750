import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="registration"
export default class extends Controller {
  static targets = ["timezone"]

  connect() {
    if (!this.timezoneTarget.value) {
      this.timezoneTarget.value = this.#systemTimeZone;
    }
  }

  get #systemTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
